import * as React from "react";
import { ChevronsUpDown } from "lucide-react";

import { Button } from "./ui/button";
import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
} from "./ui/collapsible";

export default function CollapsibleChildren({
    children,
    title,
}: {
    children: React.ReactNode;
    title: string;
}) {
    const [isOpen, setIsOpen] = React.useState(false);

    // Convert children to array for easier manipulation
    const childrenArray = React.Children.toArray(children);

    // Extract the first child to always display
    const firstChild = childrenArray[0];

    // The rest of the children that will be collapsible
    const remainingChildren = childrenArray.slice(1);

    // Only show collapse button if there are additional children
    const hasMoreChildren = remainingChildren.length > 0;

    return (
        <Collapsible
            open={isOpen}
            onOpenChange={setIsOpen}
            className="space-y-2"
        >
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                    <h3 className="font-semibold dark:text-white">{title}</h3>
                    <span className="text-gray-500 dark:text-gray-400 text-xs">
                        {childrenArray.length} items
                    </span>
                </div>

                <CollapsibleTrigger asChild>
                    <Button
                        variant="ghost"
                        size="sm"
                        className={`border ${
                            hasMoreChildren
                                ? "hover:bg-gray-100 hover:dark:bg-gray-700 dark:text-white dark:hover:text-white"
                                : ""
                        }`}
                        disabled={!hasMoreChildren}
                    >
                        <ChevronsUpDown className="h-4 w-4" />
                        <span className="sr-only">Toggle</span>
                    </Button>
                </CollapsibleTrigger>
            </div>
            {/* First child is always visible */}
            <div>{firstChild}</div>
            {!isOpen && hasMoreChildren && (
                <p className="text-gray-500 dark:text-gray-400 text-xs text-center">
                    Expand to view more
                </p>
            )}
            {hasMoreChildren && (
                <CollapsibleContent className="space-y-2">
                    {remainingChildren}
                </CollapsibleContent>
            )}
        </Collapsible>
    );
}
