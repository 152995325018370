import React, { useState, useRef, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import JSZip from "jszip";

interface PhotoUploaderProps {
    jobId?: string;
    roleName?: string;
    maxFiles?: number;
    compressionQuality?: number;
    maxWidth?: number;
    maxHeight?: number;
    maxZipSize?: number; // Maximum size of each zip in bytes
    onUploadComplete?: (imageUrls: string[], pdfUrls: string[]) => void;
    onError?: (error: Error) => void;
}

const PhotoUploader: React.FC<PhotoUploaderProps> = ({
    jobId,
    roleName,
    maxFiles = 150,
    compressionQuality = 1.0,
    maxWidth = 4000,
    maxHeight = 4000,
    maxZipSize = 25 * 1024 * 1024, // 25MB default
    onUploadComplete,
    onError,
}) => {
    const [imageFiles, setImageFiles] = useState<File[]>([]);
    const [pdfFiles, setPdfFiles] = useState<File[]>([]);
    const [progress, setProgress] = useState<number>(0);
    const [status, setStatus] = useState<string>("idle");
    const [processedCount, setProcessedCount] = useState<number>(0);
    const [zipCount, setZipCount] = useState<{ images: number; pdfs: number }>({
        images: 0,
        pdfs: 0,
    });
    const [showCompleted, setShowCompleted] = useState<boolean>(false);
    const abortControllerRef = useRef<AbortController | null>(null);

    // Keep track of processed blobs and their sizes
    const [processedImageBlobs, setProcessedImageBlobs] = useState<
        { blob: Blob; name: string }[]
    >([]);

    // Handle file drop
    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            // Separate images and PDFs
            const images: File[] = [];
            const pdfs: File[] = [];

            acceptedFiles.forEach((file) => {
                if (file.type.startsWith("image/")) {
                    images.push(file);
                } else if (file.type === "application/pdf") {
                    pdfs.push(file);
                }
            });

            // Check if total files don't exceed maximum
            const totalImageFiles = [...imageFiles, ...images];
            const totalPdfFiles = [...pdfFiles, ...pdfs];
            const totalFiles = totalImageFiles.length + totalPdfFiles.length;

            if (totalFiles > maxFiles) {
                setStatus(`Maximum of ${maxFiles} files allowed`);
                return;
            }

            setImageFiles(totalImageFiles);
            setPdfFiles(totalPdfFiles);

            const imageCount = totalImageFiles.length
                ? `${totalImageFiles.length} images`
                : "";
            const pdfCount = totalPdfFiles.length
                ? `${totalPdfFiles.length} PDFs`
                : "";
            const separator = imageCount && pdfCount ? " and " : "";

            setStatus(
                `${imageCount}${separator}${pdfCount} ready for processing`
            );
        },
        [imageFiles, pdfFiles, maxFiles]
    );

    // Dropzone configuration
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            "image/*": [
                ".jpg",
                ".jpeg",
                ".png",
                ".gif",
                ".webp",
                ".heic",
                ".heif",
            ],
            "application/pdf": [".pdf"],
        },
        maxFiles: maxFiles,
    });

    // Compress image to canvas
    const compressImage = (file: File): Promise<Blob> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    // Calculate new dimensions while preserving aspect ratio
                    let width = img.width;
                    let height = img.height;

                    if (width > maxWidth) {
                        height = Math.round(height * (maxWidth / width));
                        width = maxWidth;
                    }

                    if (height > maxHeight) {
                        width = Math.round(width * (maxHeight / height));
                        height = maxHeight;
                    }

                    // Create canvas and draw image
                    const canvas = document.createElement("canvas");
                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext("2d");

                    if (!ctx) {
                        reject(new Error("Failed to get canvas context"));
                        return;
                    }

                    ctx.drawImage(img, 0, 0, width, height);

                    // Convert to blob
                    canvas.toBlob(
                        (blob) => {
                            if (blob) {
                                resolve(blob);
                            } else {
                                reject(new Error("Failed to create blob"));
                            }
                        },
                        file.type,
                        compressionQuality
                    );
                };

                img.onerror = () => {
                    reject(new Error("Failed to load image"));
                };

                img.src = event.target?.result as string;
            };

            reader.onerror = () => {
                reject(new Error("Failed to read file"));
            };

            reader.readAsDataURL(file);
        });
    };

    // Process images and create compressed blobs
    const processAllImages = async (): Promise<
        { blob: Blob; name: string }[]
    > => {
        if (imageFiles.length === 0) {
            return [];
        }

        const totalFiles = imageFiles.length;
        const processedBlobs: { blob: Blob; name: string }[] = [];

        // Create abort controller
        abortControllerRef.current = new AbortController();
        const signal = abortControllerRef.current.signal;

        setStatus("Processing images...");
        setProcessedCount(0);

        for (let i = 0; i < totalFiles; i++) {
            // Check if operation was aborted
            if (signal.aborted) {
                throw new Error("Operation canceled by user");
            }

            const file = imageFiles[i];
            try {
                const compressedBlob = await compressImage(file);
                processedBlobs.push({
                    blob: compressedBlob,
                    name: file.name,
                });

                // Update progress
                setProcessedCount(i + 1);

                // Calculate progress based on whether we have PDFs to process
                const totalProgress = pdfFiles.length > 0 ? 40 : 80;
                setProgress(Math.round(((i + 1) / totalFiles) * totalProgress));
            } catch (error) {
                console.error(`Error processing ${file.name}:`, error);
                // Continue with next file
            }
        }

        setProcessedImageBlobs(processedBlobs);
        return processedBlobs;
    };

    // Create multiple image zip files, splitting when they exceed maxZipSize
    const createImageZips = async (
        processedBlobs: { blob: Blob; name: string }[]
    ): Promise<Blob[]> => {
        if (processedBlobs.length === 0) {
            return [];
        }

        setStatus("Creating image zip files...");

        const zipBlobs: Blob[] = [];
        let currentZip = new JSZip();
        let currentZipSize = 0;
        let filesInCurrentZip = 0;
        let zipCounter = 1;

        for (let i = 0; i < processedBlobs.length; i++) {
            const { blob, name } = processedBlobs[i];

            // If adding this file would exceed max size, finalize current zip and start a new one
            if (
                currentZipSize + blob.size > maxZipSize &&
                filesInCurrentZip > 0
            ) {
                setStatus(`Finalizing image zip ${zipCounter}...`);

                const zipBlob = await currentZip.generateAsync({
                    type: "blob",
                    compression: "DEFLATE",
                    compressionOptions: {
                        level: 6, // Balanced between size and speed
                    },
                });

                zipBlobs.push(zipBlob);

                // Start a new zip
                currentZip = new JSZip();
                currentZipSize = 0;
                filesInCurrentZip = 0;
                zipCounter++;

                setZipCount((prev) => ({ ...prev, images: zipCounter }));
            }

            // Add file to current zip
            currentZip.file(name, blob);
            currentZipSize += blob.size;
            filesInCurrentZip++;

            // Update progress
            const baseProgress = 40;
            const zipProgress =
                baseProgress + Math.round((i / processedBlobs.length) * 40);
            setProgress(Math.min(zipProgress, 80)); // Cap at 80 if there are PDFs to process
        }

        // Finalize the last zip if there are any files in it
        if (filesInCurrentZip > 0) {
            setStatus(`Finalizing image zip ${zipCounter}...`);

            const zipBlob = await currentZip.generateAsync({
                type: "blob",
                compression: "DEFLATE",
                compressionOptions: {
                    level: 6,
                },
            });

            zipBlobs.push(zipBlob);
        }

        setZipCount((prev) => ({ ...prev, images: zipCounter }));
        return zipBlobs;
    };

    // Process PDFs and create multiple zip files if needed
    const createPDFZips = async (): Promise<Blob[]> => {
        if (pdfFiles.length === 0) {
            return [];
        }

        setStatus("Processing PDFs...");

        // Create abort controller if not already created
        if (!abortControllerRef.current) {
            abortControllerRef.current = new AbortController();
        }
        const signal = abortControllerRef.current.signal;

        const zipBlobs: Blob[] = [];
        let currentZip = new JSZip();
        let currentZipSize = 0;
        let filesInCurrentZip = 0;
        let zipCounter = 1;

        for (let i = 0; i < pdfFiles.length; i++) {
            const file = pdfFiles[i];

            // Check if operation was aborted
            if (signal.aborted) {
                throw new Error("Operation canceled by user");
            }

            // Get file size
            const fileSize = file.size;

            // If adding this file would exceed max size and there are already files in the zip,
            // finalize current zip and start a new one
            if (
                currentZipSize + fileSize > maxZipSize &&
                filesInCurrentZip > 0
            ) {
                setStatus(`Finalizing PDF zip ${zipCounter}...`);

                const zipBlob = await currentZip.generateAsync({
                    type: "blob",
                    compression: "DEFLATE",
                    compressionOptions: {
                        level: 6,
                    },
                });

                zipBlobs.push(zipBlob);

                // Start a new zip
                currentZip = new JSZip();
                currentZipSize = 0;
                filesInCurrentZip = 0;
                zipCounter++;

                setZipCount((prev) => ({ ...prev, pdfs: zipCounter }));
            }

            // Add file to current zip
            currentZip.file(file.name, file);
            currentZipSize += fileSize;
            filesInCurrentZip++;

            // Update progress
            const baseProgress = imageFiles.length > 0 ? 80 : 0;
            const pdfProgress =
                baseProgress + Math.round((i / pdfFiles.length) * 15);
            setProgress(pdfProgress);
        }

        // Finalize the last zip if there are any files in it
        if (filesInCurrentZip > 0) {
            setStatus(`Finalizing PDF zip ${zipCounter}...`);

            const zipBlob = await currentZip.generateAsync({
                type: "blob",
                compression: "DEFLATE",
                compressionOptions: {
                    level: 6,
                },
            });

            zipBlobs.push(zipBlob);
        }

        setZipCount((prev) => ({ ...prev, pdfs: zipCounter }));
        return zipBlobs;
    };

    // Upload zip to server API
    const uploadToServer = async (
        zipBlob: Blob,
        fileType: "images" | "pdfs",
        zipIndex: number
    ): Promise<string> => {
        setStatus(
            `Uploading ${fileType} zip ${zipIndex + 1} of ${
                fileType === "images" ? zipCount.images : zipCount.pdfs
            } to server...`
        );

        // Create form data
        const formData = new FormData();
        const zipFileName = `${fileType}_part${zipIndex + 1}.zip`;

        // Add the zip file to form data
        formData.append("files[]", zipBlob, zipFileName);
        if (jobId) {
            formData.append("job_id", jobId);
        }
        if (roleName) {
            formData.append("role_name", roleName);
        }

        // Add metadata about multi-part upload
        formData.append("part_number", String(zipIndex + 1));
        formData.append(
            "total_parts",
            String(fileType === "images" ? zipCount.images : zipCount.pdfs)
        );

        // Create abort controller for fetch
        const controller = new AbortController();
        abortControllerRef.current = controller;

        try {
            // Upload using fetch API
            const response = await fetch("/api/files/upload", {
                method: "POST",
                body: formData,
                signal: controller.signal,
            });

            if (!response.ok) {
                throw new Error(
                    `Server returned ${response.status}: ${response.statusText}`
                );
            }

            // Parse response as JSON
            const data = await response.json();
            return (
                data.url || data.fileUrl || data.location || "Upload successful"
            );
        } catch (error) {
            if (error instanceof Error && error.name === "AbortError") {
                throw new Error("Upload canceled by user");
            }
            throw error;
        }
    };

    // Start processing and upload
    const handleSubmit = async () => {
        if (imageFiles.length === 0 && pdfFiles.length === 0) {
            setStatus("Please add files first");
            return;
        }

        try {
            setStatus("Starting process...");
            setProgress(0);
            setZipCount({ images: 0, pdfs: 0 });

            const imageUrls: string[] = [];
            const pdfUrls: string[] = [];

            // Process all images first
            let processedImageBlobs: { blob: Blob; name: string }[] = [];
            if (imageFiles.length > 0) {
                processedImageBlobs = await processAllImages();
            }

            // Create and upload image zips
            if (processedImageBlobs.length > 0) {
                const imageZipBlobs = await createImageZips(
                    processedImageBlobs
                );

                for (let i = 0; i < imageZipBlobs.length; i++) {
                    const url = await uploadToServer(
                        imageZipBlobs[i],
                        "images",
                        i
                    );
                    imageUrls.push(url);

                    // Update progress for image uploads
                    const baseProgress = 80;
                    const uploadProgress =
                        baseProgress +
                        Math.round(((i + 1) / imageZipBlobs.length) * 10);
                    setProgress(Math.min(uploadProgress, 90));
                }
            }

            // Create and upload PDF zips
            if (pdfFiles.length > 0) {
                const pdfZipBlobs = await createPDFZips();

                for (let i = 0; i < pdfZipBlobs.length; i++) {
                    const url = await uploadToServer(pdfZipBlobs[i], "pdfs", i);
                    pdfUrls.push(url);

                    // Update progress for PDF uploads
                    const baseProgress = 90;
                    const uploadProgress =
                        baseProgress +
                        Math.round(((i + 1) / pdfZipBlobs.length) * 10);
                    setProgress(Math.min(uploadProgress, 100));
                }
            }

            // Complete
            setProgress(100);
            setStatus("Upload complete!");
            onUploadComplete?.(imageUrls, pdfUrls);
            setShowCompleted(true);

            // Reset
            setTimeout(() => {
                setImageFiles([]);
                setPdfFiles([]);
                setProcessedImageBlobs([]);
                setProgress(0);
                setStatus("idle");
                setProcessedCount(0);
                setZipCount({ images: 0, pdfs: 0 });
            }, 3000);
        } catch (error) {
            console.error("Upload process failed:", error);
            setStatus(
                `Error: ${
                    error instanceof Error ? error.message : "Unknown error"
                }`
            );
            onError?.(
                error instanceof Error ? error : new Error("Unknown error")
            );
        } finally {
            abortControllerRef.current = null;
        }
    };

    // Cancel ongoing process
    const handleCancel = () => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
            setStatus("Operation canceled");
        }
    };

    // Remove a file from the list
    const removeFile = (index: number, fileType: "image" | "pdf") => {
        if (fileType === "image") {
            const newFiles = [...imageFiles];
            newFiles.splice(index, 1);
            setImageFiles(newFiles);
        } else {
            const newFiles = [...pdfFiles];
            newFiles.splice(index, 1);
            setPdfFiles(newFiles);
        }

        // Update status
        const imageCount = imageFiles.length
            ? `${imageFiles.length} images`
            : "";
        const pdfCount = pdfFiles.length ? `${pdfFiles.length} PDFs` : "";
        const separator = imageCount && pdfCount ? " and " : "";

        if (imageFiles.length === 0 && pdfFiles.length === 0) {
            setStatus("idle");
        } else {
            setStatus(
                `${imageCount}${separator}${pdfCount} ready for processing`
            );
        }
    };

    // Clear all files
    const clearFiles = () => {
        setImageFiles([]);
        setPdfFiles([]);
        setProcessedImageBlobs([]);
        setStatus("idle");
    };

    // Get all files
    const allFiles = [
        ...imageFiles.map((file) => ({ file, type: "image" as const })),
        ...pdfFiles.map((file) => ({ file, type: "pdf" as const })),
    ];

    return (
        <div className="photo-uploader w-full">
            <div
                {...getRootProps()}
                className={`dropzone w-full min-h-32 p-4 border-2 border-gray-200 dark:border-gray-600 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:bg-gray-800 hover:bg-gray-100 hover:border-blue-500 dark:hover:border-gray-500 dark:hover:bg-gray-600 ${
                    isDragActive ? "active" : ""
                }`}
                style={{
                    textAlign: "center",
                }}
            >
                <input {...getInputProps()} />
                <p className="text-red-500">
                    This is for Bulk file upload only!
                </p>
                <p>
                    Drag & drop up to {maxFiles} photos and PDFs here, or click
                    to select files
                </p>
                <p>
                    <small>
                        Images will be compressed and zipped. PDFs will be
                        packaged separately. Files will be split into multiple
                        zips if they exceed {maxZipSize / (1024 * 1024)}MB.
                    </small>
                </p>
            </div>

            {allFiles.length > 0 && (
                <div className="file-list" style={{ marginTop: "20px" }}>
                    <div
                        className="file-list-header"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <h3>Selected Files ({allFiles.length})</h3>
                        <button onClick={clearFiles}>Clear All</button>
                    </div>

                    {imageFiles.length > 0 && (
                        <h4>Images ({imageFiles.length})</h4>
                    )}
                    {imageFiles.length > 0 && (
                        <div
                            className="files-grid"
                            style={{
                                display: "grid",
                                gridTemplateColumns:
                                    "repeat(auto-fill, minmax(100px, 1fr))",
                                gap: "10px",
                                marginTop: "10px",
                                marginBottom: "20px",
                            }}
                        >
                            {imageFiles.slice(0, 12).map((file, index) => (
                                <div
                                    key={`image-${file.name}-${index}`}
                                    className="file-item"
                                    style={{ position: "relative" }}
                                >
                                    <img
                                        src={URL.createObjectURL(file)}
                                        alt={file.name}
                                        style={{
                                            width: "100%",
                                            height: "100px",
                                            objectFit: "cover",
                                            borderRadius: "4px",
                                        }}
                                    />
                                    <button
                                        onClick={() =>
                                            removeFile(index, "image")
                                        }
                                        style={{
                                            position: "absolute",
                                            top: "5px",
                                            right: "5px",
                                            background: "rgba(255,255,255,0.7)",
                                            border: "none",
                                            borderRadius: "50%",
                                            width: "20px",
                                            height: "20px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                        }}
                                    >
                                        ×
                                    </button>
                                </div>
                            ))}
                            {imageFiles.length > 12 && (
                                <div
                                    className="more-files"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        backgroundColor: "#f0f0f0",
                                        borderRadius: "4px",
                                        height: "100px",
                                    }}
                                >
                                    +{imageFiles.length - 12} more
                                </div>
                            )}
                        </div>
                    )}

                    {pdfFiles.length > 0 && <h4>PDFs ({pdfFiles.length})</h4>}
                    {pdfFiles.length > 0 && (
                        <div
                            className="files-grid"
                            style={{
                                display: "grid",
                                gridTemplateColumns:
                                    "repeat(auto-fill, minmax(100px, 1fr))",
                                gap: "10px",
                                marginTop: "10px",
                            }}
                        >
                            {pdfFiles.slice(0, 12).map((file, index) => (
                                <div
                                    key={`pdf-${file.name}-${index}`}
                                    className="file-item"
                                    style={{ position: "relative" }}
                                >
                                    <div
                                        style={{
                                            width: "100%",
                                            height: "100px",
                                            backgroundColor: "#f2f2f2",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: "4px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontSize: "24px",
                                                color: "#e74c3c",
                                            }}
                                        >
                                            PDF
                                        </div>
                                        <div
                                            style={{
                                                fontSize: "10px",
                                                marginTop: "5px",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                                width: "90%",
                                                textAlign: "center",
                                            }}
                                        >
                                            {file.name}
                                        </div>
                                    </div>
                                    <button
                                        onClick={() => removeFile(index, "pdf")}
                                        style={{
                                            position: "absolute",
                                            top: "5px",
                                            right: "5px",
                                            background: "rgba(255,255,255,0.7)",
                                            border: "none",
                                            borderRadius: "50%",
                                            width: "20px",
                                            height: "20px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                        }}
                                    >
                                        ×
                                    </button>
                                </div>
                            ))}
                            {pdfFiles.length > 12 && (
                                <div
                                    className="more-files"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        backgroundColor: "#f0f0f0",
                                        borderRadius: "4px",
                                        height: "100px",
                                    }}
                                >
                                    +{pdfFiles.length - 12} more
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}

            {status !== "idle" && (
                <div className="status" style={{ marginTop: "20px" }}>
                    <p>{status}</p>
                    {processedCount > 0 && imageFiles.length > 0 && (
                        <p>
                            Processed {processedCount} of {imageFiles.length}{" "}
                            images
                        </p>
                    )}
                    {zipCount.images > 0 && (
                        <p>
                            Creating {zipCount.images} zip file
                            {zipCount.images > 1 ? "s" : ""} for images
                        </p>
                    )}
                    {zipCount.pdfs > 0 && (
                        <p>
                            Creating {zipCount.pdfs} zip file
                            {zipCount.pdfs > 1 ? "s" : ""} for PDFs
                        </p>
                    )}
                    {progress > 0 && (
                        <div
                            className="progress-bar"
                            style={{
                                width: "100%",
                                backgroundColor: "#e0e0e0",
                                borderRadius: "4px",
                                marginTop: "10px",
                            }}
                        >
                            <div
                                className="progress"
                                style={{
                                    width: `${progress}%`,
                                    height: "10px",
                                    backgroundColor: "#4CAF50",
                                    borderRadius: "4px",
                                }}
                            ></div>
                        </div>
                    )}
                </div>
            )}

            <div
                className="actions"
                style={{ marginTop: "20px", display: "flex", gap: "10px" }}
            >
                <button
                    onClick={handleSubmit}
                    disabled={
                        (imageFiles.length === 0 && pdfFiles.length === 0) ||
                        status.includes("Processing") ||
                        status.includes("Uploading") ||
                        status.includes("Creating") ||
                        status.includes("Finalizing")
                    }
                    style={{
                        padding: "10px 20px",
                        backgroundColor:
                            imageFiles.length === 0 && pdfFiles.length === 0
                                ? "#ccc"
                                : "#4CAF50",
                        color: "white",
                        border: "none",
                        borderRadius: "10px",
                        cursor:
                            imageFiles.length === 0 && pdfFiles.length === 0
                                ? "not-allowed"
                                : "pointer",
                    }}
                >
                    Process & Upload
                </button>

                {(status.includes("Processing") ||
                    status.includes("Uploading") ||
                    status.includes("Creating") ||
                    status.includes("Finalizing")) && (
                    <button
                        onClick={handleCancel}
                        style={{
                            padding: "10px 20px",
                            backgroundColor: "#f44336",
                            color: "white",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                        }}
                    >
                        Cancel
                    </button>
                )}

                {showCompleted && (
                    <div className="bg-green-50 rounded-lg p-4 border border-green-500 text-green-500">
                        <p>
                            Upload complete! Refresh the page to view the
                            uploaded files.
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PhotoUploader;
